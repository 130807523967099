import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import openNotification from "../../../Components/Notifications";
import {
  Descriptions,
  Divider,
  Spin,
  Typography,
  Popconfirm,
  Result,
  Tag,
  Image,
} from "antd";
import { IoArrowBackOutline, IoArrowForwardOutline } from "react-icons/io5";
import { getOneRestaurant } from "../../../API/payAppFetch";
import COLORS from "../../../Style/colors";
// import EditRestaurantForm from "./Components/EditRestaurantForm";
// import PaymentRestaurant from "./Components/PaymentRestaurant";
import CustomButton from "../../../Components/CustomButton";
// import PaymentTransactions from "./Components/PaymentTransactions";
import FOODICS from "../../../assets/images/Companies/foodics.png";
import MARN from "../../../assets/images/Companies/marnPOS.png";
import IWAITER from "../../../assets/images/icon-iwaiter.png";
// import RestaurantInfo from "./Components/RestaurantInfo";
// import AddBranchForm from "./Components/AddBranchForm";
import { SettingOutlined } from "@ant-design/icons";
import RestaurantInfo from "./RestaurantInfo";
import EditRestaurantForm from "./EditRestaurantForm";
import AddBranchForm from "./AddBranchForm";
import PaymentInformation from "./PaymentInformation";
// import EditRestaurantService from "./Components/EditRestaurantService";
// import PaymentInformation from "./Components/PaymentInformation";
// import AppRestaurantInfo from "./Components/AppRestaurantInfo";
// import EditGeneralInformation from "./Components/EditGeneralInformation";

const { Text, Title } = Typography;
const OneRestaurantPayApp = () => {
  const intl = useIntl();
  // get the params from the url;
  const { id } = useParams();
  const navigate = useNavigate();
  const { rtl } = useSelector((state) => state.settings);
  const [restaurant, setRestaurant] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [showGeneralInformation, setShowGeneralInformation] = useState(false);

  useEffect(() => {
    if (id !== "new") {
      getRestaurantInfo(id);
    } else {
      setLoading(false);
    }
  }, [id, showGeneralInformation]);

  useEffect(() => {
    // scroll to top of page;
    window.scrollTo(0, 0);
  }, []);

  const getRestaurantInfo = async (id) => {
    try {
      const res = await getOneRestaurant(id);
      if (!res?.data?.restaurant) {
        openNotification({
          title: "error",
          description: "something went wrong",
          type: "error",
          rtl: true,
        });
        setError(true);
        setLoading(false);
        return;
      }
      setRestaurant(res?.data?.restaurant);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      openNotification({
        title: "error",
        description: "something went wrong",
        type: "error",
        rtl,
      });
    }
  };

  const deleteRestaurantInfo = async () => {
    try {
      setLoading(true);
      //   const res = await deleteRestaurant(restaurant._id);
      //   if (res?.data?.status === "success") {
      //     openNotification({
      //       title: intl.formatMessage({ id: "savedSuccessfully" }),
      //       type: "success",
      //       rtl,
      //     });
      //     setLoading(false);
      //     navigate("/restaurants");
      //   }
    } catch (error) {
      console.log(error);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  const deleteMenu = async () => {
    try {
      setLoading(true);
      const data = {
        restaurantId: id,
      };
      //   const res = await deleteRestaurantMenu(data);
      //   if (res?.data?.status === "success") {
      //     openNotification({
      //       title: intl.formatMessage({ id: "menuDeleteSuccessfully" }),
      //       type: "success",
      //       rtl,
      //     });
      //     setLoading(false);
      //   }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      {error ? (
        <Result
          status={"404"}
          title={"404"}
          subTitle={"Sorry, the page you visited does not exist."}
          extra={
            <CustomButton
              className={`btn-text border-8 px-5 pb-5`}
              type='primary'
              onClick={() => navigate(-1)}
              text={intl.formatMessage({ id: "goBack" })}
            />
          }
        />
      ) : (
        <Spin spinning={loading}>
          <div
            style={
              rtl
                ? {
                    position: "absolute",
                    top: 0,
                    right: 0,
                    zIndex: 2,
                    display: "flex",
                    alignItems: "center",
                  }
                : {
                    position: "absolute",
                    top: 0,
                    left: 0,
                    zIndex: 2,
                    display: "flex",
                    alignItems: "center",
                  }
            }
          >
            <Title
              level={3}
              className='pointer'
              onClick={() => navigate(-1)}
              // style={{ position: "absolute", zIndex: 1 }}
            >
              {rtl ? (
                <IoArrowForwardOutline className='pointer' />
              ) : (
                <IoArrowBackOutline className='pointer' />
              )}
            </Title>
          </div>
          <br />

          <div
            style={{
              backgroundColor: COLORS.white,
              marginTop: 20,
              width: "100%",
              minHeight: "70vh",
              marginRight: "auto",
              marginLeft: "auto",
              borderRadius: 10,
              padding: 20,
              border: `1px dashed ${COLORS.primary}`,
            }}
            className='light-shadow'
          >
            <RestaurantInfo restaurant={restaurant} />

            <Divider />
            <Title level={3}>{intl.formatMessage({ id: "edit" })}</Title>
            <EditRestaurantForm
              restaurant={restaurant}
              setRestaurant={setRestaurant}
            />
            <Divider />
            <Title level={3}>{intl.formatMessage({ id: "addBranch" })}</Title>
            <AddBranchForm
              restaurant={restaurant}
              setRestaurant={setRestaurant}
            />
          </div>
          <div
            style={{
              backgroundColor: COLORS.white,
              marginTop: 20,
              width: "100%",
              // minHeight: "70vh",
              marginRight: "auto",
              marginLeft: "auto",
              borderRadius: 10,
              padding: 20,
              border: `1px dashed ${COLORS.primary}`,
            }}
            className='light-shadow'
          >
            <Title level={3}>{intl.formatMessage({ id: "integration" })}</Title>
            <Descriptions column={1} className='my-font' bordered>
              <Descriptions.Item label={intl.formatMessage({ id: "company" })}>
                {restaurant?.integration?.posName === "foodics" ? (
                  <Image src={FOODICS} width={120} />
                ) : restaurant?.integration?.posName === "Marn" ? (
                  <Image src={MARN} width={80} />
                ) : restaurant?.integration?.posName === "iWaiter" ? (
                  <Image src={IWAITER} width={40} />
                ) : (
                  "no company"
                )}
              </Descriptions.Item>
              <Descriptions.Item label={intl.formatMessage({ id: "status" })}>
                {restaurant?.integration?.status === "active" ? (
                  <Tag color='success'>{"Active"}</Tag>
                ) : restaurant?.integration?.status === "Inactive" ? (
                  <Tag color='red'>{"Inactive"}</Tag>
                ) : (
                  <Tag color='red'>{restaurant?.integration?.status}</Tag>
                )}
              </Descriptions.Item>
            </Descriptions>
            <CustomButton
              className={`btn-text border-8 px-5 pb-5 mt-4`}
              type='primary'
              text={intl.formatMessage({ id: "manage" })}
              onClick={() => {
                console.log("manage");
              }}
              icon={<SettingOutlined />}
            />
          </div>
          <div
            style={{
              backgroundColor: COLORS.white,
              marginTop: 20,
              width: "100%",
              // minHeight: "70vh",
              marginRight: "auto",
              marginLeft: "auto",
              borderRadius: 10,
              padding: 20,
              border: `1px dashed ${COLORS.primary}`,
            }}
            className='light-shadow'
          >
            <Title level={3}>{intl.formatMessage({ id: "payments" })}</Title>

            <Divider />
            <PaymentInformation
              restaurant={restaurant}
              setRestaurant={setRestaurant}
            />
            <Divider />
          </div>
        </Spin>
      )}
    </div>
  );
};

export default OneRestaurantPayApp;
