import axios from "axios";
import { apiPayApp } from "./index";

export const loginAdminPayApp = (data) => {
  return apiPayApp.post(`/sign-in`, data);
};

export const getAllAppRestaurants = () => {
  return apiPayApp.get(`/admin-restaurants`);
};

export const getOneRestaurant = (id) => {
  return apiPayApp.get(`/admin-restaurants/${id}`);
};

export const updatePayAppRestaurant = (id, data) => {
  return apiPayApp.patch(`/admin-restaurants/${id}`, data);
};

export const addBranch = (id, data) => {
  return apiPayApp.post(`/admin-restaurants/${id}/branches`, data);
};

export const uploadToMyFatoorahAPI = (restaurantId, name) => {
  return apiPayApp.post(
    `/wallets/document/admin/addToMyFatoorah?restaurantId=${restaurantId}&name=${name}`
  );
};

export const createPayRestaurant = (data) => {
  return apiPayApp.post(`/admin-restaurants`, data);
};

export const createMyFatoorahPayApp = (data) => {
  return apiPayApp.post("/create-my-fatoorah", data);
};
